/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import Header from '@solid-ui-blocks/Header/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from '@flexiblocks/templates/saas/_theme'
import styles from './_privacy.styles'

const TermsPage = props => {
  const { allBlockContent } = useStaticQuery(query)
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  // useEffect(() => {
  //   loader();
  // }, []);

  // const loader = () => {
  //   const s = document.createElement("script");
  //   const tag = document.getElementsByTagName("script")[0];
  //   s.src = "https://cdn.iubenda.com/iubenda.js";
  //   tag.parentNode.insertBefore(s, tag);
  //   if (window.addEventListener) {
  //     window.addEventListener("load", loader, false);
  //   } else if (window.attachEvent) {
  //     window.attachEvent("onload", loader);
  //   } else {
  //     window.onload = loader;
  //   }
  // };

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Terms of Service' />
      <Header content={content['header-others']} />
      <Divider space='5' />
      <Container variant='wide' sx={styles.root}>
      <p style={{textAlign: "center", color: "#404040", fontSize: 20}}>
          <b>Terms of Service</b>
        </p>
        <p>
          Welcome to FieldNext ("Company"), the website and mobile services by
          DotNext Technologies Pvt. Ltd. ("us", "we", or "our"). This page (read in conjunction
          with the documents referred to on it) details the terms of Service
          ("Terms", "Terms and Conditions") by which you may use our online
          and/or mobile services, web site, and software provided on or in
          connection with the service (collectively “Service”).&nbsp;This
          Agreement applies to all visitors, users, and others who access the
          Service.
          <p>
            PLEASE READ THIS AGREEMENT CAREFULLY BEFORE ACCESSING OR USING THE
            APPLICATIONS. BY ACCESSING OR USING THE APPLICATIONS, YOU AGREE TO
            BECOME BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT. IF YOU
            DO NOT AGREE TO ALL THE TERMS AND CONDITIONS OF THIS AGREEMENT, THEN
            YOU MAY NOT ACCESS OR USE THE APPLICATIONS.
          </p>
        </p>
        <h3 className={styles.subHead}>Our Service</h3>
        <p>
          FieldNext is a field workforce management platform for micro, small, and medium
          enterprises. FieldNext facilitate a company's employee and
          management in realtime movement tracking, remote attendance, job reporting & management,
          customer/client managament and reporing for better time utilisation,
          work efficiency and productivity enhancement. Subject to the terms and
          conditions of this Agreement, you are hereby granted a non-exclusive,
          limited, non-transferable, freely revocable license to use the Service
          for your official use only and as permitted by the features of the
          Service.
        </p>
        <h3 className={styles.subHead}>Eligibility</h3>
        <p>
          You may use the Service only if agree all applicable local, state, national, and international laws, rules and
          regulations.&nbsp;By agreeing to these Terms of Service, you represent
          that you are at least the age of majority in your state or province or
          country of residence, or that you are the age of majority in your
          state or province or country of residence and you have given us your
          consent to allow any of your minor dependents to use this
          site.&nbsp;Previously removed user by Company is not allow to re-use
          the service.&nbsp;You may not use our products for any illegal or
          unauthorized purpose nor may you, in the use of the Service, violate
          any laws in your jurisdiction (including but not limited to copyright
          laws).
        </p>
        <h3 className={styles.subHead}>Account</h3>
        <p>
          Accounts that you are able to establish with the Service give you
          access to the services and functionality that we may establish and
          maintain from time to time and in our sole discretion. You may never
          use another User’s account without permission. When creating your
          account, you must provide accurate and complete information. You are
          solely responsible for the activity that occurs on your account, and
          you must keep your account password secure. We encourage you to use
          “strong” passwords (passwords that use a combination of upper and
          lower case letters, numbers and symbols) with your account. You must
          notify us immediately of any breach of security or unauthorized use of
          your account. Company will not be liable for any losses caused by any
          unauthorized use of your account. You may control your User profile
          and how you interact with the Service by changing settings that are
          made available to you.
        </p>
        <h3 className={styles.subHead}>Pricing & Billing</h3>
        <p>
          If you choose to use the service that have charges or subscriptions
          you agree to the pricing and payment terms and that we may update them
          from time to time. Company may add new features for additional fees
          and charges, or amend fees and charges for existing services, at any
          time in its sole discretion. Any change to our pricing or payment
          terms shall become effective in the billing cycle following notice of
          such change to you as provided in this Agreement.
          <p>
            This service is provided on initial 14 days free trial and afterward
            monthly or Annual subscription payment basis. Company reserves right
            for different pricing of the service in value and currency for
            different geographical areas considering efforts in infrastructures,
            supports, taxs and service deliveries.
          </p>
          <p>
            All subscription and access charges for the service are payable in
            advance. Company is not responsible for any charges or expenses you
            incur resulting from charges billed by Company in accordance with
            this Agreement (e.g. overdrawn accounts, exceeding credit card
            limit, etc.). By providing a credit card number or other payment
            method with advance authorization features (e.g. some Stripe, PayPal
            accounts), you authorize Company to continue charging the payment
            method for all charges due Company until your account is settled and
            your subscription is terminated by either you or Company. Company
            reserves the right to limit the number of accounts that may be
            charged to a credit card or other payment or identification method
            per unique user.
          </p>
          <p>
            You may cancel your account at any time; however, there are no
            refunds for any unused time on a subscription, any license or
            subscription fees for any portion of the Service, any content or
            data associated with your account, or for anything else. Upon
            cancelling any Subscription Features, your subscription will be
            valid until your paid period is completed.
          </p>
        </p>
        <h3 className={styles.subHead}>User Conduct and Rules</h3>
        <p>
          You agree and undertake that when using a Service, you will not: (a)
          for any unlawful purpose; (b) to solicit others to perform or
          participate in any unlawful acts; (c) to violate any international,
          federal, provincial or state regulations, rules, laws, or local
          ordinances; (d) to infringe upon or violate our intellectual property
          rights or the intellectual property rights of others; (e) to harass,
          abuse, insult, harm, defame, slander, disparage, intimidate, or
          discriminate based on gender, sexual orientation, religion, ethnicity,
          race, age, national origin, or disability; (f) to submit false or
          misleading information; (g) to upload or transmit viruses or any other
          type of malicious code that will or may be used in any way that will
          affect the functionality or operation of the Service or of any related
          website, other websites, or the Internet; (h) to collect or track the
          personal information of others; (i) to spam, phish, pharm, pretext,
          spider, crawl, or scrape; (j) for any obscene or immoral purpose; or
          (k) to interfere with or circumvent the security features of the
          Service or any related website, other websites, or the Internet. We
          reserve the right to terminate your use of the Service or any related
          website for violating any of the prohibited uses. Without any prior
          notice, we may change the Service; stop or limit the Service or
          features of the Service, to you. You are solely responsible for your
          interactions with other Company Users. We reserve the right, but have
          no obligation, to monitor disputes between you and other Users.
          Company shall have no liability for your interactions with other
          Users, or for any User’s action or inaction. You are responsible for
          all the mobile data usage resulting from the use of Service.
        </p>
        <h3 className={styles.subHead}>Intellectual Property Rights</h3>
        <p>
          We reserve all of our rights, including but not limited to any and all
          software, images, text, graphics, illustrations, logos, patents,
          trademarks, service marks, copyrights, photographs, audio, videos,
          music, trade secrets, and any other proprietary right that we may have
          in our App or web site, its content, and the goods and services that
          may be provided. The use of our rights and property requires our prior
          written consent. We are not providing you with any implied or express
          licenses or rights by making services available to you and you will
          have no rights to make any commercial uses of our App, web site or
          service without our prior written consent.
        </p>
        <h3 className={styles.subHead}>
          Accuracy, Completeness And Timeliness Of Information
        </h3>
        <p>
          We are not responsible if information made available on this site
          and/or mobile app ("Application") is not accurate, complete or
          current. The material on this application&nbsp;are provided for
          general information only and should not be relied upon or used as the
          sole basis for making decisions without consulting primary, more
          accurate, more complete or more timely sources of information. Any
          reliance on the material on this site is at your own risk. This site
          may contain certain historical information. Historical information,
          necessarily, is not current and is provided for your reference only.
          We reserve the right to modify the contents of this site at any time,
          but we have no obligation to update any information on our site. You
          agree that it is your responsibility to monitor changes to our site.
        </p>
        <h3 className={styles.subHead}>Privacy</h3>
        <p>
          Our Privacy Policy governs your submission of personal information to
          this service. Check details at{" "}
          <a href="/privacy/index.html">Our Privacy Policy</a>
        </p>
        <p>
          Note: We are taking our best and maximum security efforts for data
          cummunication and storage using well known Amazon and Google clouds,
          https sites and APIs. However, as effective as encryption technology
          is, no security system is impenetrable. We cannot guarantee the
          security of our database, nor can we guarantee that information you
          supply won't be intercepted while being transmitted to us over the
          Internet. Any transmission of information by you to our Mobile App is
          at your own risk. We strongly recommend that you do not disclose your
          password to others and keep your mobile device personal.
        </p>
        <h3 className={styles.subHead}>Third-Party Links</h3>
        <p>
          Certain content, products and services available via our Service may
          include materials from third-parties. Third-party links on this
          application&nbsp;may direct you to third-party websites that are not
          affiliated with us. We are not responsible for examining or evaluating
          the content or accuracy and we do not warrant and will not have any
          liability or responsibility for any third-party materials or websites,
          or for any other materials, products, or services of third-parties. We
          are not liable for any harm or damages related to the purchase or use
          of goods, services, resources, content, or any other transactions made
          in connection with any third-party websites. Please review carefully
          the third-party's policies and practices and make sure you understand
          them before you engage in any transaction. Complaints, claims,
          concerns, or questions regarding third-party products should be
          directed to the third-party.
        </p>
        <h3 className={styles.subHead}>
          User Comments, Feedback And Other Submissions
        </h3>
        <p>
          If, at our request, you send certain specific submissions (for example
          contest entries) or without a request from us you send creative ideas,
          suggestions, proposals, plans, or other materials, whether online, by
          email, by postal mail, or otherwise (collectively, 'comments'), you
          agree that we may, at any time, without restriction, edit, copy,
          publish, distribute, translate and otherwise use in any medium any
          comments that you forward to us. We are and shall be under no
          obligation (1) to maintain any comments in confidence; (2) to pay
          compensation for any comments; or (3) to respond to any comments. We
          may, but have no obligation to, monitor, edit or remove content that
          we determine in our sole discretion are unlawful, offensive,
          threatening, libelous, defamatory, pornographic, obscene or otherwise
          objectionable or violates any party’s intellectual property or these
          Terms of Service. You agree that your comments will not violate any
          right of any third-party, including copyright, trademark, privacy,
          personality or other personal or proprietary right. You further agree
          that your comments will not contain libelous or otherwise unlawful,
          abusive or obscene material, or contain any computer virus or other
          malware that could in any way affect the operation of the Service or
          any related website. You may not use a false e-mail address, pretend
          to be someone other than yourself, or otherwise mislead us or
          third-parties as to the origin of any comments. You are solely
          responsible for any comments you make and their accuracy. We take no
          responsibility and assume no liability for any comments posted by you
          or any third-party.
        </p>
        <h3 className={styles.subHead}>
          Disclaimer of Warranties; Limitation of Liability
        </h3>
        <p>
          WE DO NOT GUARANTEE, REPRESENT OR WARRANT THAT YOUR USE OF OUR SERVICE
          WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE. WE DO NOT WARRANT
          THAT THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE WILL
          BE ACCURATE OR RELIABLE. YOU AGREE THAT FROM TIME TO TIME WE MAY
          REMOVE THE SERVICE FOR INDEFINITE PERIODS OF TIME OR CANCEL THE
          SERVICE AT ANY TIME, WITHOUT NOTICE TO YOU. YOU EXPRESSLY AGREE THAT
          YOUR USE OF, OR INABILITY TO USE, THE SERVICE IS AT YOUR SOLE RISK.
          THE SERVICE AND ALL PRODUCTS AND SERVICES DELIVERED TO YOU THROUGH THE
          SERVICE ARE (EXCEPT AS EXPRESSLY STATED BY US) PROVIDED 'AS IS' AND
          'AS AVAILABLE' FOR YOUR USE, WITHOUT ANY REPRESENTATION, WARRANTIES OR
          CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING ALL
          IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, MERCHANTABLE
          QUALITY, FITNESS FOR A PARTICULAR PURPOSE, DURABILITY, TITLE, AND
          NON-INFRINGEMENT. IN NO CASE SHALL BUDDHA SOFTWARE, OUR DIRECTORS,
          OFFICERS, EMPLOYEES, AFFILIATES, AGENTS, CONTRACTORS, INTERNS,
          SUPPLIERS, SERVICE PROVIDERS OR LICENSORS BE LIABLE FOR ANY INJURY,
          LOSS, CLAIM, OR ANY DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, SPECIAL,
          OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING, WITHOUT LIMITATION
          LOST PROFITS, LOST REVENUE, LOST SAVINGS, LOSS OF DATA, REPLACEMENT
          COSTS, OR ANY SIMILAR DAMAGES, WHETHER BASED IN CONTRACT, TORT
          (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, ARISING FROM
          YOUR USE OF ANY OF THE SERVICE OR ANY PRODUCTS PROCURED USING THE
          SERVICE, OR FOR ANY OTHER CLAIM RELATED IN ANY WAY TO YOUR USE OF THE
          SERVICE OR ANY PRODUCT, INCLUDING, BUT NOT LIMITED TO, ANY ERRORS OR
          OMISSIONS IN ANY CONTENT, OR ANY LOSS OR DAMAGE OF ANY KIND INCURRED
          AS A RESULT OF THE USE OF THE SERVICE OR ANY CONTENT (OR PRODUCT)
          POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICE, EVEN
          IF ADVISED OF THEIR POSSIBILITY. BECAUSE SOME STATES OR JURISDICTIONS
          DO NOT ALLOW THE EXCLUSION OR THE LIMITATION OF LIABILITY FOR
          CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH STATES OR JURISDICTIONS,
          OUR LIABILITY SHALL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW.
        </p>
        <h3 className={styles.subHead}>Indemnification</h3>
        <p>
          You agree to indemnify, defend and hold harmless Buddha Software and
          our parent, subsidiaries, affiliates, partners, officers, directors,
          agents, contractors, licensors, service providers, subcontractors,
          suppliers, interns and employees, harmless from any claim or demand,
          including reasonable attorneys’ fees, made by any third-party due to
          or arising out of your breach of these Terms of Service or the
          documents they incorporate by reference, or your violation of any law
          or the rights of a third-party.
        </p>
        <h3 className={styles.subHead}>Severability</h3>
        <p>
          In the event that any provision of these Terms of Service is
          determined to be unlawful, void or unenforceable, such provision shall
          nonetheless be enforceable to the fullest extent permitted by
          applicable law, and the unenforceable portion shall be deemed to be
          severed from these Terms of Service, such determination shall not
          affect the validity and enforceability of any other remaining
          provisions.
        </p>
        <h3 className={styles.subHead}>Termination</h3>
        <p>
          The obligations and liabilities of the parties incurred prior to the
          termination date shall survive the termination of this agreement for
          all purposes. These Terms of Service are effective unless and until
          terminated by either you or us. You may terminate these Terms of
          Service at any time by notifying us that you no longer wish to use our
          Services, or when you cease using our site. If in our sole judgment
          you fail, or we suspect that you have failed, to comply with any term
          or provision of these Terms of Service, we also may terminate
          temporarily or permanently this agreement at any time without notice
          and you will remain liable for all amounts due up to and including the
          date of termination; and/or accordingly may deny you access to our
          Services (or any part thereof).
        </p>
        <h3 className={styles.subHead}>Entire Agreement</h3>
        <p>
          The failure of us to exercise or enforce any right or provision of
          these Terms of Service shall not constitute a waiver of such right or
          provision. These Terms of Service and any policies or operating rules
          posted by us on this site or in respect to The Service constitutes the
          entire agreement and understanding between you and us and govern your
          use of the Service, superseding any prior or contemporaneous
          agreements, communications and proposals, whether oral or written,
          between you and us (including, but not limited to, any prior versions
          of the Terms of Service). Any ambiguities in the interpretation of
          these Terms of Service shall not be construed against the drafting
          party.
        </p>
        <h3 className={styles.subHead}>Change to Terms Of Service</h3>
        <p>
          You can review the most current version of the Terms of Service at any
          time at this page. We reserve the right, at our sole discretion, to
          update, change or replace any part of these Terms of Service by
          posting updates and changes to our website. It is your responsibility
          to check our website periodically for changes. Your continued use of
          or access to our website or the Service following the posting of any
          changes to these Terms of Service constitutes acceptance of those
          changes.
        </p>
        <h3 className={styles.subHead}>Governing Law</h3>
        <p>
          These Terms will be interpreted in accordance with the laws of the
          India. You and we agree to submit to the exclusive jurisdiction of a
          court located in Patna, Bihar, India for any dispute, claim or actions
          in relation to these Terms or the use of services.
        </p>
        <h3 className={styles.subHead}>General</h3>
        <p>
          We reserve the right to refuse service to anyone for any reason at any
          time. You understand that your content (not including credit card
          information), may be transferred unencrypted and involve (a)
          transmissions over various networks; and (b) changes to conform and
          adapt to ical requirements of connecting networks or devices. Credit
          card information is always encrypted during transfer over networks.
          You agree not to reproduce, duplicate, copy, sell, resell or exploit
          any portion of the Service, use of the Service, or access to the
          Service or any contact on the website through which the service is
          provided, without express written permission by us. The headings used
          in this agreement are included for convenience only and will not limit
          or otherwise affect these Terms.
        </p>
        <h3 className={styles.subHead}>Contact Information</h3>
        <p>
          Questions about the Terms of Service should be sent to us at
          contact@fieldnext.io. This Agreement was last modified on September 7th,
          2021.
        </p>
      </Container>
      <Divider space='5' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

const query = graphql`
  query fieldnextTermsBlockContent {
    allBlockContent(filter: { page: { in: ["fieldnext/terms", "fieldnext/shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default TermsPage
